<template>
  <div class="student" ref="student">
    <keep-alive>
      <list-template
          :loading="loading"
          :total="total"
          :current-page="page"
          :table-data="tableData"
          :table-config="tableConfig"
          @onChangePage="changeCurrentPage"
          @onExportData="onExport"
          @onHandle="handleEdit"
      >
        <template v-slot:title>
          <el-form ref="form" :model="form" label-width="0" style="padding-right: 40px">
            <div class="flex flex-align">
              <el-form-item>
                <el-input v-model="form.student_name" @enter="onSearch" size="small"
                          placeholder="搜索学生姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="筛选学生来源" v-model="form.source" @enter="onSearch"
                           :popper-append-to-body="false">
                  <el-option v-for="option in studentSource" :label="option.label" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="筛选校区" v-model="form.school_id" @enter="onSearch"
                           :popper-append-to-body="false">
                  <el-option v-for="option in schoolConfig" :label="option.school_name" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">搜索</el-button>
              <el-button size="small" style="margin-left: 16px" debounce @click.prevent="reset">重置</el-button>
              <!--              <el-button debounce size="small" type="primary" @click.prevent="onExport">导出数据</el-button>-->
            </div>
          </el-form>
        </template>
      </list-template>
    </keep-alive>
  </div>
</template>

<script>
import ListTemplate from '@/components/pages/List'
import {mapState} from 'vuex'
import {getSchool, getStudentSource} from "@/api/common";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      schoolConfig: [],
      // 表格搜索配置
      form: {},
      // 表格配置
      tableConfig: [
        {prop: 'student_status', label: '学生状态'},
        {prop: 'source_cn', label: '学生来源'},
        {prop: 'student_name', label: '学生姓名'},
        {prop: 'school_name', label: '校区'},
        {prop: 'paid_money', label: '实缴金额'},
        {prop: 'money', label: '退费金额'},
        {prop: 'refund_transactors_name', label: '退费办理人'},
        {prop: 'refund_day', label: '退费时间'},
        {prop: 'refund_type', label: '退费方式'},
        {prop: 'creater_name', label: '退费发起人'},
        {prop: 'created_at', label: '发起时间'},
        {
          label: '操作',
          width: 90,
          handle: true,
          showOverflowTooltip: false,
          render: () => {
            return ['查看']
          }
        }
      ],
      studentSource: [],
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: [],
    }
  },
  computed: {
    ...mapState(['page'])
  },
  components: {
    ListTemplate
  },
  mounted() {
    this.$store.commit('setPage', 1);
    getStudentSource().then(res => {
      this.studentSource = res.data.data;
    })
    getSchool().then(res => {
      this.schoolConfig = res.data.data.entity_school;
    })
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let {form, page} = this
      this.loading = true;
      this.$_register.get('api/refund/offline-PC/refund-record-list', {params: {...form, page}}).then(res => {
        let data = res.data.data
        if (Array.isArray(data)) {
          this.tableData = []
          this.total = 0
        } else {
          this.tableData = data.list
          this.total = data.page.total
        }
      }).finally(() => this.loading = false)
    },
    // 搜索功能
    onSearch() {
      this.$store.commit('setPage', 1)
      this.getData()
    },
    onExport(){
      let params = {...this.form, page: this.page}
      this.$_register('api/refund/offline-PC/refund-record-list-export', {params}).then(res => {
        this.$tools.download('退费记录', res.data)
      })

    },
    //重置搜索框
    reset() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = null
      })
      this.$store.commit('setPage', 1)
      this.getData()
    },

    handleEdit(row) {
      this.$router.push('./details/'+row.source+'?id=' + row.id)
    },

  }
}
</script>
<style lang="scss" scoped>

::v-deep .el-form .el-input {
  //width: auto !important;
}

::v-deep .el-form-item {
  margin-right: 20rem !important;
  margin-bottom: 12rem !important;
}

::v-deep .el-button--small {
  padding: 9rem 15rem;
  font-size: 12rem;
  border-radius: 3rem;
}

::v-deep .el-button--small {
  height: 32rem;
  margin-bottom: 12rem;
}
</style>